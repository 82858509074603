/**
 * Company schema
 */

import rules from './validationRules';
import i18n from '../i18n';
import { datatypes, getBooleanTypeReferences } from './datatypes';
import { getItemStatusTypeReferences } from './itemStatus';
import { roleAdmin, roleSuperAdmin } from './accessRolesModel';

const companyFieldId = {
  name: 'id',
  type: datatypes.string,
  description: 'Unique id of the company',
  label: '',
  allowedRoles: {
    read: [],
    write: [],
  },
};

const companyFieldCompanyName = {
  name: 'companyName',
  type: datatypes.string,
  description: 'Name of the Company',
  minLength: 1,
  maxLength: 96,
  label: i18n.tc('company.fields.companyName', 1),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [rules.required],
};

const companyFieldEmail = {
  name: 'email',
  type: datatypes.string,
  description: 'Contact email of the company',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('company.fields.email', 1),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [rules.email],
};

const companyFieldPhoneNumber = {
  name: 'phoneNumber',
  type: datatypes.string,
  description: 'Contact phone number of the company.',
  minLength: 0,
  maxLength: 128,
  label: i18n.tc('company.fields.phoneNumber', 1),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [],
};

const companyFieldPublicParams = {
  name: 'publicParams',
  type: datatypes.json,
  description: 'Public Parameters associated to the Company.',
  label: i18n.tc('company.fields.publicParams', 1),
  minLength: 0,
  maxLength: 1024,
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [rules.isJson],
};

const companyFieldPrivateParams = {
  name: 'privateParams',
  type: datatypes.json,
  description: 'Private Parameters associated to the Company.',
  label: i18n.tc('company.fields.privateParams', 1),
  minLength: 0,
  maxLength: 1024,
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [roleSuperAdmin],
  },
  rules: [rules.isJson],
};

const companyFieldCompanyIsActive = {
  name: 'companyIsActive',
  type: datatypes.boolean,
  description: 'The company is currently hosted by the Village.',
  hint: i18n.tc('company.hints.companyIsActive', 1),
  label: i18n.tc('company.fields.companyIsActive', 1),
  references: getBooleanTypeReferences(),
  allowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    write: [roleAdmin, roleSuperAdmin],
  },
  rules: [],
};

const companyFieldItemStatus = {
  name: 'itemStatus',
  type: datatypes.itemStatus,
  description: 'Etat de l élément.',
  label: i18n.tc('company.fields.itemStatus', 1),
  references: getItemStatusTypeReferences(),
  allowedRoles: {
    read: [roleSuperAdmin],
    write: [],
  },
  rules: [],
};

const company = {
  fields: [
    companyFieldId,
    companyFieldCompanyName,
    companyFieldEmail,
    companyFieldPhoneNumber,
    companyFieldPublicParams,
    companyFieldPrivateParams,
    companyFieldCompanyIsActive,
    companyFieldItemStatus,
  ],
  crudAllowedRoles: {
    read: [roleAdmin, roleSuperAdmin],
    readInactive: [roleSuperAdmin],
    create: [roleAdmin, roleSuperAdmin],
    update: [roleAdmin, roleSuperAdmin],
    delete: [],
  },
};

export default company;
export {
  company,
  companyFieldId,
  companyFieldCompanyName,
  companyFieldEmail,
  companyFieldPhoneNumber,
  companyFieldPublicParams,
  companyFieldPrivateParams,
  companyFieldCompanyIsActive,
  companyFieldItemStatus,
};
