/* eslint-disable no-console */
<template>
  <div class="about">
    <editable-data-table
      :model="model"
      :items=records
      :headers="tableHeaders"
      :crudAccess="crudAccess"
      :crudLabels="crudLabels"
      :isItemUpdatable=isItemUpdatable
      :isListGetAll.sync=isListGetAll
      :loading.sync=loading
      :sortBy="['companyName']"
      :sortDesc="[false]"
      @create="createRecord"
      @refreshData="refreshData"
      @update="updateRecord"
      @switchIsGetListAll="handleSwitchIsListGetAll"
      ref="editabledatatable">
    </editable-data-table>
  </div>
</template>

<script>

import {
  company,
  companyFieldCompanyName,
  companyFieldEmail,
  companyFieldPhoneNumber,
  // companyFieldPublicParams,
  // companyFieldPrivateParams,
  companyFieldCompanyIsActive,
  // companyFieldItemStatus,
} from '@/model/company';
import { mapGetters } from 'vuex';
import i18n from '../i18n';
import EditableDataTable from '../components/EditableDataTable.vue';
import dbAPI from '../api/companies.api';
import { defaultCrudAccess, getAllowedCrudForUser, isFieldAllowedForRoles } from '../model/accessRolesModel';

export default {
  name: 'Company',
  components: {
    EditableDataTable,
  },
  data: () => ({
    tablename: 'company',
    crudAccess: defaultCrudAccess,
    crudLabels: {},
    isListGetAll: false,
    loading: true,
    model: [],
    record: {},
    records: [],
    tableHeaders: [],
  }),

  created() {
    this.crudAccess = getAllowedCrudForUser(company.crudAllowedRoles, this.userRoles);
    if (!this.crudAccess.read) {
      const msg = this.$t('errMsg.forbiddenAccess_fmt',
        { operation: this.$t('crudLabels.read').toLowerCase() });
      this.$store.dispatch('displayAlert', msg);
      this.$router.go(-1);
    }
    this.refreshData();
  },

  watch: {
    $route() {
      this.refreshData();
    },

    isListGetAll() {
      this.setisListGetAll();
    },

  },

  computed: {
    ...mapGetters([
      'userRoles',
    ]),
  },

  methods: {
    createRecord(record) {
      const complete = this.validateRecordBeforeWriteAPICall(record);
      /* eslint-disable no-param-reassign */
      if (complete) record = complete;
      else return;
      /* eslint-enable no-param-reassign */
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.creating') }));
      this.$nextTick(() => {
        dbAPI.create(record)
          .then((data) => {
            if (data.status === 201) {
              const c = data.data;
              if (c.privateParams && typeof c.privateParams === 'object') {
                c.privateParams = `${JSON.stringify(c.privateParams)}`;
              }
              if (c.publicParams && typeof c.publicParams === 'object') {
                c.publicParams = `${JSON.stringify(c.publicParams)}`;
              }
              if (c.companyIsActive || this.isListGetAll) {
                this.records.push(data.data);
              }
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.created') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.creation').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          }).then(() => {
            this.loading = false;
          });
      });
    },

    getList() {
      this.loading = true;
      this.$nextTick(() => {
        dbAPI.getList(this.isListGetAll).then((response) => {
          this.records = response.data.data;
          /* eslint-disable no-param-reassign */
          this.records.map((c) => {
            // Use a template conversion so as privateParams are considered as string
            // and not as Object (then displayed [object Object]).
            // Please, do not ask why JSON.stringify do not do the job by itself....
            if (c.privateParams && typeof c.privateParams === 'object') {
              c.privateParams = `${JSON.stringify(c.privateParams)}`;
            }
            if (c.publicParams && typeof c.publicParams === 'object') {
              c.publicParams = `${JSON.stringify(c.publicParams)}`;
            }
            return c;
          });
          /* eslint-disable no-param-reassign */
        }).catch((error) => {
          const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 2,
            { operation: this.$t('crudLabels.read').toLowerCase() });
          const apiErrorMsg = error?.response?.data?.error_msg || '';
          console.error(`Error : ${msg}`, error, apiErrorMsg);
          this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
        }).then(() => {
          this.loading = false;
        });
      });
    },

    handleSwitchIsListGetAll() {
      this.isListGetAll = !this.isListGetAll;
    },

    isItemUpdatable(item) {
      return this.crudAccess.update
        && item.companyName.toLowerCase() !== '<undefined>';
    },

    refreshData() {
      this.crudAccess = getAllowedCrudForUser(company.crudAllowedRoles, this.userRoles);
      this.setModel();
      this.setCrudLabels();
      this.setTableHeaders();
      this.getList();
    },

    setCrudLabels() {
      this.crudLabels = {
        create: i18n.t(`${this.tablename}.c`),
        retrieve: i18n.tc(`${this.tablename}.r`, 1),
        update: i18n.tc(`${this.tablename}.u`, 1),
        delete: i18n.tc(`${this.tablename}.d`, 1),
        list: i18n.tc(`${this.tablename}.list`, 1),
        confirmDelete: i18n.tc(`${this.tablename}.confirmD`, 1),
      };
    },

    setModel() {
      this.model = company.fields.filter((f) => (f.label)
                                              && isFieldAllowedForRoles(f, this.userRoles));
    },

    setisListGetAll(newVal) {
      this.getList(newVal);
    },

    setTableHeaders() {
      const headers = [
        { label: '', name: 'data-table-expand', isTech: true },
        companyFieldCompanyName,
        companyFieldEmail,
        companyFieldPhoneNumber,
        companyFieldCompanyIsActive,
      ];
      headers.push({
        label: i18n.tc('formActions', 2),
        name: 'actions',
        isTech: true,
      });
      this.tableHeaders = headers
        .filter((hh) => hh.isTech || isFieldAllowedForRoles(hh, this.userRoles))
        .map((h) => ({ text: h.label, value: h.name }));
    },

    updateRecord(editedRecord) {
      const complete = this.validateRecordBeforeWriteAPICall(editedRecord);
      /* eslint-disable no-param-reassign */
      if (complete) editedRecord = complete;
      else return;
      /* eslint-enable no-param-reassign */
      this.loading = true;
      this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.runningMsg_Fmt`, 1,
        { operation: this.$t('crudLabels.updating') }));
      this.$nextTick(() => {
        dbAPI.update(editedRecord.id, editedRecord)
          .then((data) => {
            if (data.status === 201) {
              const localIndex = this.records.findIndex((item) => (item.id === editedRecord.id));
              const c = data.data;
              if (c.privateParams && typeof c.privateParams === 'object') {
                c.privateParams = `${JSON.stringify(c.privateParams)}`;
              }
              if (c.publicParams && typeof c.publicParams === 'object') {
                c.publicParams = `${JSON.stringify(c.publicParams)}`;
              }
              if (c.companyIsActive || this.isListGetAll) {
                this.records.splice(localIndex, 1, data.data);
              } else {
                this.records.splice(localIndex, 1);
              }
              this.$store.dispatch('displayInfo', this.$tc(`${this.tablename}.doneMsg_Fmt`, 1,
                { operation: this.$t('crudLabels.updated') }));
            }
          })
          .catch((error) => {
            const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
              { operation: this.$t('crudLabels.update').toLowerCase() });
            const apiErrorMsg = error?.response?.data?.error_msg || '';
            console.error(`Error : ${msg}`, error, apiErrorMsg);
            this.$store.dispatch('displayAlert', `${msg}: ${error}. ${apiErrorMsg}`);
          })
          .then(
            this.loading = false,
          );
      });
    },

    validateRecordBeforeWriteAPICall(record) {
      let result = null;
      try {
        /* eslint-disable no-param-reassign */
        // Conversion to validate format
        if (record.privateParams) {
          record.privateParams = JSON.parse(record.privateParams);
        } else record.privateParams = {};
        if (record.publicParams) {
          record.publicParams = JSON.parse(record.publicParams);
        } else record.publicParams = {};
        /* eslint-enable no-param-reassign */
        result = record;
      } catch (error) {
        const msg = this.$tc(`${this.tablename}.abortedMsg_Fmt`, 1,
          { operation: this.$t('crudLabels.creation').toLowerCase() });
        this.$store.dispatch('displayAlert', `${msg}: ${error}.`);
      }
      return result;
    },

  },
};
</script>
